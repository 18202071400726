import { FC } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const paths = ['', '/', '/login', '/logout'];
export const PREV_ROUTE = 'PREV_ROUTE';

export const AuthRoute: FC<RouteProps> = () => {
  const { isAuthenticated, isLoading, user, activeTenant } = useAuth();
  const location = useLocation();
  const tenantMetadata = JSON.parse(activeTenant?.metadata || '{}');
  const stripe = tenantMetadata?.stripe;
  const hasFreeAccess =
    tenantMetadata?.hasFreeAccess === true ||
    tenantMetadata?.hasFreeAccess === 'true';
  const doesSubscriptionExist = stripe?.subscriptionId;
  const isSubscriptionActive =
    stripe?.subscriptionStatus === 'active' ||
    stripe?.subscriptionStatus === 'trialing';

  const prevRoute = window.localStorage.getItem(PREV_ROUTE);
  const pathname = location.pathname;

  if (!isAuthenticated && !isLoading) {
    if (!prevRoute && pathname && !paths.includes(pathname)) {
      window.localStorage.setItem(PREV_ROUTE, `${pathname}${location.search}`);
    }

    return <Navigate to={`/login${location.search}`} replace />;
  }

  if (user) {
    if (!hasFreeAccess) {
      if (doesSubscriptionExist && !isSubscriptionActive) {
        return <Navigate to="/billing" />;
      }

      if (
        !doesSubscriptionExist &&
        pathname !== '/onboarding' &&
        pathname !== '/pricing'
      ) {
        return <Navigate to="/onboarding" />;
      }
    }

    if (prevRoute && pathname !== prevRoute) {
      window.localStorage.removeItem(PREV_ROUTE);
      return <Navigate to={prevRoute} />;
    }
  } else if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
};
