import { extendTheme, PartialReablocksTheme, theme } from 'reablocks';

const themeOverride: PartialReablocksTheme = {
  // Override the default theme here
  components: {
    button: {
      base: [
        theme.components.button.base,
        '!text-sm !border-gray-500 font-sans text-gray-100 font-medium rounded-[5px] hover:text-white' +
          ' hover:!border-gray-100'
      ].join(' '),
      colors: {
        default: {
          text: [theme.components.button.colors.default, 'text-gray-100'].join(
            ' '
          )
        },
        primary: {
          ...theme.components.button.colors.primary,
          outline: [
            theme.components.button.colors.primary.outline,
            'border !border-primary-400'
          ].join(' ')
        },
        secondary: {
          ...theme.components.button.colors.secondary,
          text: [
            theme.components.button.colors.secondary.text,
            'text-secondary-600'
          ].join(' ')
        }
      },
      variants: {
        text: [theme.components.button.variants.text, '!border-0'].join(' ')
      }
    },
    list: {
      listItem: {
        content: [theme.components.list.listItem.content, 'text-md'].join(' ')
      }
    },
    select: {
      selectInput: {
        base: [
          theme.components.select.selectInput.base,
          'hover:after:content-none focus-within:after:content-none border-gray-200 focus-within:border-gray-100'
        ].join(' '),
        input: [
          theme.components.select.selectInput.input,
          'text-md cursor-text'
        ].join(' '),
        chip: {
          base: [
            theme.components.select.selectInput.chip.base,
            'text-md bg-transparent text-gray-100 font-normal border !border-gray-500 !rounded-none mb-1.5'
          ].join(' ')
        },
        suffix: {
          expand: [
            theme.components.select.selectInput.suffix.expand,
            'fill-secondary-600'
          ].join(' ')
        }
      },
      selectMenu: {
        base: [
          theme.components.select.selectMenu.base,
          'rounded-lg bg-gray-900 my-2'
        ].join(' '),
        option: {
          base: [
            theme.components.select.selectMenu.option.base,
            'text-gray-100 px-3 py-2'
          ].join(' '),
          hover: [
            theme.components.select.selectMenu.option.hover,
            'hover:text-white hover:bg-red-400/20'
          ].join(' ')
        }
      }
    },
    chip: {
      base: [theme.components.chip.base, '!justify-start !py-[5px]'].join(' ')
    },
    input: {
      base: [theme.components.input.base, 'after:hidden'].join(' '),
      input: [theme.components.input.input, 'font-mono'].join(' '),
      focused: [theme.components.input.focused, '!border-white'].join(' ')
    },
    textarea: {
      base: [theme.components.textarea.base, 'after:hidden'].join(' ')
    },
    dialog: {
      inner: [theme.components.dialog.inner, 'border-0'].join(' '),
      header: {
        base: [theme.components.dialog.header.base, '[&>div]:w-full'].join(' '),
        closeButton: [
          theme.components.dialog.header.closeButton,
          '!text-2xl'
        ].join(' ')
      }
    },
    popover: {
      base: [theme.components.popover.base, 'border-0'].join(' ')
    },
    card: {
      base: [theme.components.card.base, 'border-0 rounded-[5px]'].join(' ')
    },
    block: {
      base: [theme.components.block.base, 'mb-0'].join(' ')
    },
    notification: {
      positions: [theme.components.notification.positions, 'top-10 h-fit'].join(
        ' '
      )
    },
    tooltip: {
      base: [theme.components.tooltip.base, 'text-xs bg-black'].join(' ')
    },
    jsonTree: {
      node: {
        ...theme.components.jsonTree.node,
        label: 'text-blue-100'
      }
    }
  }
};

export const blackwireTheme = extendTheme(theme, themeOverride);
